import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { faUser, faLock, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Hidden } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import { injectIntl } from 'react-intl';
import { ModalActions,AuthActions } from '../../store/actions';
import ErrorSnackBar from '../ErrorSnackBar';
// import saml from './saml.svg';
import { LOGIN_PAGE_CSS } from '../../common/cssConstants';
import { COLORS } from '../../common/Colors';
import { AuthListItem } from '../../components/auth/AuthBtnRight';
import CustomTextField from '../auth/CustomTextField';
import config from '../../config';
import './mycss.css';
import { page } from '../../services/Segment';
import CountDownTimer from '../CountDownTimer';

const { customerDeployment, companyName , website, loginPageLogoUrl, loginPageBrandLogoUrl } = config;

const styles = () => ({
    linkForText: {
        textDecoration: 'none',
        color: '#1168CD',
    },
    listItems: {
        display: 'flex',
        flexDirection: 'row',
        padding: '17px 100px 0px 0px',
        color: '#67686a',
        fontSize: '18px',
        lineHeight: '22px',
    },
    listItemGutters: {
        paddingRight: 24,
        paddingLeft: 24,
    },
    navigations: {
        color: '#67686a',
        fontWeight: '500',
        fontSize: 16,
    },
    arrowIcon: {
        color: COLORS.white,
        marginRight: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-right',
    },
    raisedBtn: {
        width: '100%',
        height: LOGIN_PAGE_CSS.submitButtonHeight,
        border: 'none',
        borderRadius: '20px',
        marginTop: '1rem',
        backgroundColor: LOGIN_PAGE_CSS.submitButtonBackgroundColor,
        color: LOGIN_PAGE_CSS.submitButtonColor,
        lineHeight: '22px',
        '&:hover': {
            backgroundColor: LOGIN_PAGE_CSS.submitButtonBackgroundColor,
        },
    },
    forgotPasswordTxt: {
        color: '#979797',
        cursor: 'pointer',
        fontSize: LOGIN_PAGE_CSS.normalFontSize,
    },
    checkBoxRoot: {
        '&>span>svg': {
            fontSize: '22px',
        },
    },
    rememberMeLabel: {
        color: '#979797',
        fontSize: LOGIN_PAGE_CSS.normalFontSize,
        marginLeft: '7px',
    },
    rememberMeRoot: {
        marginLeft: 0,
    },
    loginDisabled: {
        color: LOGIN_PAGE_CSS.submitButtonDisableColor,
        backgroundColor: LOGIN_PAGE_CSS.submitButtonDisableBackgroundColor,
    },
    colorError: {
        color: LOGIN_PAGE_CSS.errorFontColor,
        display: 'flex',
        justifyContent: 'center',
        margin: '5px 0px 5px 0px',
        fontWeight: 600,
        fontSize: '12px',
    },
    formWrapperWithError: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ff4c54',
        borderRadius: '2px',
        height: '26px',
        width: '100%',
    },
    fullList: {
        width: 'auto',
        padding: 20,
    },
    bars: {
        color: '#0092e6',
        fontSize: '25px',
        marginRight: '25px',
        marginTop: '15px',
    },
    root: {
        maxHeight: '100vh',
        height: '100vh',
        overflowY: 'auto',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    whiteCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    whiteCardLeft: {
        display: 'flex',
        width: '65%',
        maxWidth: '900px',
        height: '500px',
        backgroundColor: '#fff',
        borderRadius: 50,
        margin: 50,
        boxShadow: '0px 2px 13px -4px',
    },
    imageContainer: {
        width: '60%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        borderBottomLeftRadius: '50px',
        borderTopLeftRadius: '50px',
        backgroundColor:'#0F5286'
    },
    autonomiqLogo: {
        paddingTop: '25px',
        width: '45%',
        height: 'auto',
        position: 'absolute',
        left: '50px',
        top: '215px',
    },
    aiQ: {
        width: 'auto',
        height: '700px',
        position: 'absolute',
        top: '-46px',
        left: '-165px',
    },
    otherLogo: {
        width: '72%',
        height: 'auto',
    },
    whiteCardRight: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formArea: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: 6,
        paddingBottom: 6,
    },
    loginWith: {
        margin: '25px 0px 5px 0px',
        fontSize: 14,
        color: '#979797',
    },
    textFieldInputAdornment: {
        marginLeft: '10px',
        marginRight: '11px',
        color: COLORS.gray,
    },
    autonomiqLogoContainer: {
        height: '37%',
        width: '100%',
        textAlign: 'center',
        paddingTop: '10%'

    },
    resendEmailTimer: {
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        fontSize:'16px',

    }
});

class SigninBase extends React.Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            rememberMe: false,
            isFocus: false,
            openNav: false,
        };
    }

    componentDidMount() {
        
        page();
        this.setDefaultFields();

        if(sessionStorage.getItem('reset_password_link_sent')) {
            this.setState({
                resetPasswordLinkAlreadySent:true,
                username:sessionStorage.getItem('reset_password_link_sent_username')||''
            },()=>{
                this.props.showSnackBar(`Reset password link has been sent to your registered email ID ${sessionStorage.getItem('reset_password_link_sent')}`, '', true,50000,true)
            })
        }
    }

    componentWillUnmount() {
        this.props.clearAuthError();
        this.props.clearAuthSuccess();
    }

    onKeyHandler = (event) => {
        if (event.charCode === 13) {
            this.LogIn();
        }
    };

    setDefaultFields = async () => {
        const data = await JSON.parse(localStorage.getItem('registerUser'));
        if (data) {
            this.setState(
                {
                    username: data.email,
                    password: data.password,
                },
                async () => {
                    await localStorage.removeItem('registerUser');
                },
            );
        }
    };

    toggleDrawer = (open) => () => {
        this.setState({
            openNav: open,
        });
    };

    LogIn = () => {
        const userdata = {
            username: this.state.username,
            password: this.state.password,
            check: this.state.rememberMe,
        };
        // this.props.CallLogIn(userdata);
        if (this.props.authInfo.authType && this.props.authInfo.authType === 'default') {
            this.props.CallLogIn(userdata);
        } else {
            this.props.getAuthDetails(userdata.username).then((res)=>{
                if(res && res.data && res.data.email && res.data.isFirstLogin && res.data.authType!=='saml') {

                    if(sessionStorage.getItem('reset_password_link_sent')) {
                        this.setState({
                            resetPasswordLinkAlreadySent:true
                        },()=>{
                            this.props.showSnackBar(`Reset password link has been sent to your registered email ID ${sessionStorage.getItem('reset_password_link_sent')}`, '', true,50000,true)
                        })
                    } else {

                        this.setState({
                            resetPasswordLinkAlreadySent:true
                        });
    
                        sessionStorage.setItem('reset_password_link_sent',res.data.email);
                        sessionStorage.setItem('reset_password_link_sent_username',this.state.username);

                        this.props.showSnackBar(`Reset password link has been sent to your registered email ID ${res.data.email}`, '', true,50000)
                        const { authenticateEmail } = this.props;
                        authenticateEmail(res.data.email);
                    }
                }
            })
        }
    };

    resendPasswordResetEmail = () => {

        this.setState({
            hideTimer:false
        })

        this.props.showSnackBar(`Reset password link has been sent to your registered email ID ${sessionStorage.getItem('reset_password_link_sent')}`, '', true,50000,true)
        const { authenticateEmail } = this.props;
        authenticateEmail(sessionStorage.getItem('reset_password_link_sent'));


    } 

    handleEmail = (event) => {
        const { value } = event.currentTarget;
        this.setState({ username: value.trim(), isFocus: true }, () => {
            if (this.props.error) {
                this.props.clearAuthError();
            }
        });
    };

    handlePassword = (event) => {
        const { value } = event.currentTarget;
        this.setState({ password: value.trim(), isFocus: true });
    };

    // handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const status = this.enableSubmit;
    //     if (status) {
    //         this.allowResubmit = false;
    //         const { routerStore, authenticationStore } = this.props;
    //         try {
    //             const isAuthenticated = await authenticationStore.authenticate(this.username, this.password);
    //             if (isAuthenticated) {
    //                 if (this.rememberMe) {
    //                     authenticationStore.remember();
    //                 }
    //                 routerStore.goTo('home');
    //             }
    //         } finally {
    //             this.allowResubmit = true;
    //         }
    //     } else {
    //         this.showError();
    //     }
    // };

    // showError = () => {
    //     const usenameStatus = !this.username.length;
    //     const passwordStatus = this.passwordInError;
    //     if (usenameStatus) {
    //         this.error = 'User name can not be empty.';
    //     } else if (passwordStatus) {
    //         this.error = 'account invalid.';
    //     }
    // };

    handleRememberMe = (event, checked) => {
        // this..rememberMe = checked;

        this.setState({
            rememberMe: checked,
        });
    };
    // else{
    //     this.setState({
    //         remember:true
    //     })
    // }
    // };

    handleForget = () => {
        this.props.history.push('forgotPassword');
    };

    checkForValid = () => {
        // In functional components we can`t get element values by ref so
        // using getElementById here
        // check user focused on input then check from state
        const { username, password, isFocus } = this.state;
        if (this.props.authInfo.authType && this.props.authInfo.authType === 'default') {
            if (isFocus) {
                return !username || !password;
            }
            if (!document.getElementById('username') || !document.getElementById('pwd')) {
                return true;
            }

            if (navigator.userAgent.includes('Chrome')) {
                // its a temp hack because we can not get input value untill we manually focus on screen
                const allInput = document.querySelectorAll('input:-webkit-autofill');

                if (allInput.length) {
                    return false;
                }
            }

            return !document.getElementById('username').value || !document.getElementById('pwd').value;
        }
        if (isFocus) {
            return !username;
        }
        if (!document.getElementById('username')) {
            return true;
        }
        return false;
    };

    render() {
        const { classes, error, authInfo, isSnackBarOpen } = this.props;
        const { formatMessage } = this.props.intl;
        const { username, password,resetPasswordLinkAlreadySent } = this.state;
        const navList = [
            <ListItem
                key={1}
                button
                component="a"
                href={website}
                target="_blank"
                classes={{
                    gutters: classes.listItemGutters,
                }}
            >
                <ListItemText primary={<Typography className={classes.navigations}>{formatMessage({ id: 'nav.home' })}</Typography>} />
            </ListItem>,
            <ListItem
                key={2}
                component="a"
                href={website}
                target="_blank"
                button
                classes={{
                    gutters: classes.listItemGutters,
                }}
            >
                <ListItemText primary={<Typography className={classes.navigations}>{formatMessage({ id: 'nav.products' })}</Typography>} />
            </ListItem>,
            <ListItem
                key={3}
                component="a"
                href={website}
                target="_blank"
                button
                classes={{
                    gutters: classes.listItemGutters,
                }}
            >
                <ListItemText primary={<Typography className={classes.navigations}>{formatMessage({ id: 'nav.products' })}</Typography>} />
            </ListItem>,
            <ListItem
                key={4}
                component="a"
                href={website}
                target="_blank"
                button
                classes={{
                    gutters: classes.listItemGutters,
                }}
            >
                <ListItemText primary={<Typography className={classes.navigations}>{formatMessage({ id: 'nav.resources' })}</Typography>} />
            </ListItem>,
            <ListItem
                key={5}
                button
                component="a"
                href={`${website}/team/`}
                target="_blank"
                classes={{
                    gutters: classes.listItemGutters,
                }}
            >
                <ListItemText primary={<Typography className={classes.navigations}>{formatMessage({ id: 'nav.about' })}</Typography>} />
            </ListItem>,
            <AuthListItem href="https://autonomiq.io/#autonomiq-signup-form" text={formatMessage({ id: 'nav.contact' })} />,
        ];

        const sideList = (
            <div className={classes.fullList}>
                {companyName === 'AutonomIQ' && (
                    <List>
                        {navList.map((item, index) => {
                            if (index < navList.length - 1) {
                                return item;
                            }
                            return null;
                        })}
                    </List>
                )}
                <Divider />
                <List>{navList[navList.length - 1]}</List>
            </div>
        );

        const usernamePasswordErrorStyle =
            error ? { padding: 5, textAlign: 'center', height: 'auto' } : null;

        return (
            <div className={classes.root}>
                {customerDeployment === 'false' && (
                    <div className={classes.navigation}>
                        <Hidden only={['sm', 'xs']}>
                            {companyName === 'AutonomIQ' ? <List className={classes.listItems}>{navList.map((item) => item)}</List> : null}
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <div className={classes.navigation}>
                                <FontAwesomeIcon
                                    icon={faBars}
                                    aria-label="faBars"
                                    id="faBars"
                                    className={classes.bars}
                                    onClick={this.toggleDrawer(true)}
                                />
                                <SwipeableDrawer
                                    anchor="right"
                                    open={this.state.openNav}
                                    onClose={this.toggleDrawer(false)}
                                    onOpen={this.toggleDrawer(true)}
                                    disableSwipeToOpen
                                >
                                    <div tabIndex={0} role="button" onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)}>
                                        {companyName === 'AutonomIQ' ? sideList : null}
                                    </div>
                                </SwipeableDrawer>
                            </div>
                        </Hidden>
                    </div>
                )}
                <div className={classes.whiteCard}>
                    <div className={classes.whiteCardLeft}>
                        <div className={classes.imageContainer}>
                            {companyName.toLocaleLowerCase() === 'autonomiq' ? (
                                <div>
                                    {/* <img src={logoLarge} alt="" className={classes.aiQ} /> */}
                                    <img src={loginPageLogoUrl} alt=""  />
                                </div>
                            ) : (
                                <img src={loginPageLogoUrl} alt=""  />
                            )}
                        </div>

                        <div className={classes.whiteCardRight}>
                            <div className={classes.autonomiqLogoContainer}>
                            <img height={63} width={273} src={loginPageBrandLogoUrl} alt=""  />
                            </div>
                            <div style={{flex:1,width:'100%'}}>
                            <form
                                noValidate
                                className={classes.form}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (!this.checkForValid()) {
                                        this.LogIn();
                                    }
                                }}
                            >
                                <div className={classes.formArea}>
                                    <CustomTextField
                                        autoFocus
                                        name="username"
                                        disabled={(resetPasswordLinkAlreadySent) || this.props.authInfo.authType && this.props.authInfo.authType === 'default'}
                                        id="username"
                                        aria-label="User Name"
                                        placeholder={formatMessage({ id: 'signin.username' })}
                                        onChange={this.handleEmail}
                                        onKeyPress={this.onKeyHandler}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" className={classes.textFieldInputAdornment}>
                                                    <FontAwesomeIcon icon={faUser} aria-label="faUser" id="faUser" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        color={COLORS.gray}
                                        value={username}
                                    />
                                    <CustomTextField
                                        divStyle={{ display:  authInfo.authType && !(authInfo.isFirstLogin) && authInfo.authType === 'default' ? 'unset' : 'none' }}
                                        autoFocus
                                        id="pwd"
                                        ariaLabel="Password"
                                        name="password"
                                        placeholder={formatMessage({ id: 'signin.password' })}
                                        onChange={this.handlePassword}
                                        onKeyPress={this.onKeyHandler}
                                        type="password"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" className={classes.textFieldInputAdornment}>
                                                    <FontAwesomeIcon icon={faLock} aria-label="gaLock" id="faLock" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        color={COLORS.gray}
                                        error={Boolean(error)}
                                        value={password}
                                    />
                                    {error && (
                                        <div className={classes.formWrapperWithError} style={usernamePasswordErrorStyle}>
                                            {error && <p className={classes.colorError}>{error}</p>}
                                        </div>
                                    )}
                                    {  authInfo.authType && !(authInfo.isFirstLogin) && authInfo.authType === 'default' && (
                                        <FormGroup row className={classes.formGroup}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox value="RememberMe" onChange={this.handleRememberMe} className={classes.checkBoxRoot} />
                                                }
                                                label={formatMessage({ id: 'signin.remember' })}
                                                labelPlacement="end"
                                                classes={{
                                                    root: classes.rememberMeRoot,
                                                    label: classes.rememberMeLabel,
                                                }}
                                            />
                                            <Typography variant="body1" className={classes.forgotPasswordTxt} onClick={this.handleForget}>
                                                {formatMessage({ id: 'signin.forgot' })}
                                            </Typography>
                                        </FormGroup>
                                    )}
                                    {
                                        !(resetPasswordLinkAlreadySent) &&
                                        <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.raisedBtn}
                                        color="primary"
                                        disabled={this.checkForValid()}
                                        classes={{
                                            disabled: classes.loginDisabled,
                                        }}
                                    >
                                        {authInfo   && authInfo.authType && authInfo.authType === 'default' ? (
                                            formatMessage({ id: 'signin.login' })
                                        ) : (
                                             
                                                <>{formatMessage({ id: 'next' })} &gt;&gt;&gt;</>
                                        )}
                                    </Button>
                                    }

                                    {

                                        (resetPasswordLinkAlreadySent) &&
                                        <>
                                        <div style={{marginTop:10,fontSize: 14,color: 'rgb(151, 151, 151)'}}>
                                            {`If you didn't recieve the email`}
                                         </div>   
                                        <Button
                                            type="button"
                                            onClick={this.resendPasswordResetEmail}
                                            variant="contained"
                                            className={classes.raisedBtn}
                                            color="primary"
                                            disabled={!this.state.hideTimer}
                                            classes={{
                                                disabled: classes.loginDisabled,
                                            }}
                                        >
                                            {
                                                <div className={classes.resendEmailTimer}>
                                                    <div style={{marginRight:4}}>{'Resend Email'}</div>
                                                    {
                                                        !this.state.hideTimer &&
                                                        <div style={{marginRight:4}}>{'In'}</div>
                                                    }
                                                   {
                                                       !this.state.hideTimer &&
                                                       <CountDownTimer totalTimeStamp={150} onCountDownFinish ={()=>{
                                                        this.setState({
                                                            hideTimer:true,
                                                        })
                                                    }}/>

                                                   }

                                                </div>
                                            }
                                        
                                        </Button>
                                        <div style={{ display: 'flex' }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    color: '#979797',
                                                }}
                                            >
                                            {`Go back to `}
                                        </p>
                                    
                                    <a onClick={()=>{
                                        sessionStorage.removeItem('reset_password_link_sent');
                                        sessionStorage.removeItem('reset_password_link_sent_username');

                                        window.location.reload();
                                        
                                    }}  style={{
                                        cursor:'pointer'
                                    }} className={classes.linkForText}>
                                        <p style={{ fontSize: 14 }}>
                                            &nbsp; {'Login'}
                                        </p>
                                    </a>
                                </div>
                                        </>

                                    }
                                    
                                    
                                    
                                </div>
                                {/* {authInfo && authInfo.authType && authInfo.authType === 'default' && (
                                    <p className={classes.loginWith}>{formatMessage({ id: 'signin.alternate' })}</p>
                                )} */}
                                {/* {(authInfo.authType && authInfo.authType === 'default') && <div className={classes.buttonsArea}>
                                    <div
                                        className={classes.loginButtons}
                                        onClick={() => {
                                            window.location.href = `./platform/v1/auth/google/login?hostUrl=${(window
                                                .location.protocol === 'https:'
                                                ? 'https://'
                                                : 'http://') +
                                                window.location.hostname}`;
                                        }}
                                    >
                                        <img
                                            src={google}
                                            style={{ width: '20px' }}
                                        />
                                        <p className={classes.platform}>
                                            Google
                                        </p>
                                    </div>
                                    <div
                                        className={classes.loginButtons}
                                        style={{ cursor: 'default' }}
                                    >
                                        <img
                                            src={github}
                                            className={classes.opacity}
                                            style={{ width: '22px' }}
                                        />
                                        <p
                                            className={[
                                                classes.platform,
                                                classes.opacity,
                                            ].join(' ')}
                                        >
                                            GitHub
                                        </p>
                                    </div>
                                    <div
                                        className={classes.loginButtons}
                                        style={{ cursor: 'default' }}
                                    >
                                        <img
                                            src={jira}
                                            className={classes.opacity}
                                            style={{ width: '20px' }}
                                        />
                                        <p
                                            className={[
                                                classes.platform,
                                                classes.opacity,
                                            ].join(' ')}
                                        >
                                            JIRA
                                        </p>
                                    </div>
                                </div>} */}
                                <div style={{ display: 'flex' }}>
                                    <p
                                        style={{
                                            fontSize: 14,
                                            color: '#979797',
                                        }}
                                    >
                                        {formatMessage({ id: 'signin.noaccount' })}
                                    </p>
                                    
                                    <a target="_blank"  href={`https://autonomiq.io/#autonomiq-signup-form?utm_source=${window.location.hostname}`} className={classes.linkForText}>
                                        <p style={{ fontSize: 14 }}>
                                            &nbsp;
                                            {formatMessage({ id: 'nav.contact' })}
                                        </p>
                                    </a>
                                </div>
                            </form>
                            </div>
                            {/* {(authInfo.authType && authInfo.authType === 'default') && <div style={{ display: 'flex' }}>
                                <div
                                    className={classes.loginButtons}
                                    style={{ cursor: 'default', width: '5rem' }}
                                    onClick={() => {
                                        window.location.assign(`http://localhost:8005/v1/auth/saml`);
                                    }}
                                >
                                    <img
                                        src={saml}
                                        style={{ width: '2.5rem' }}
                                    />


                                </div>

                            </div>} */}
                            <div>
                                {isSnackBarOpen ? <ErrorSnackBar /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SigninBase.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
    return {
        state,
        error: state.authReducer.error,
        authInfo: state.authReducer.authInfo,
        isSnackBarOpen: state.modalReducer.isSnackBarOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        CallLogIn: (userdata) => dispatch(AuthActions.authenticate(userdata)),
        clearAuthError: () => dispatch(AuthActions.clearAuthError()),
        clearAuthSuccess: () => dispatch(AuthActions.clearAuthSuccess()),
        getAuthDetails: (user) => dispatch(AuthActions.getAuthDetails(user)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        authenticateEmail: (email) => dispatch(AuthActions.authenticateEmail(email)),


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(SigninBase)));
