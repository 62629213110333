import { SIGNUP_PAGE_CSS } from '../../common/cssConstants';

export const styles = () => ({
    otherLogo: {
        width: '72%',
        height: 'auto',
    },
    card: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    colorError: {
        backgroundColor: SIGNUP_PAGE_CSS.errorFontColor,
    },
    colorSuccess: {
        backgroundColor: SIGNUP_PAGE_CSS.successFontColor,
    },
    trialText: {
        color: SIGNUP_PAGE_CSS.normalFontColor,
        fontSize: '21px',
        fontWeight: '300',
    },
    boxFlex: {
        display: 'flex',
    },
    errorText: {
        fontSize: 12,
    },
    formWrapper: {
        padding: '10px 0px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
    },
    formWrapperWithError: {
        paddingTop: '5px !important',
        marginBottom: '10px',
        marginTop: '10px',
        borderRadius: '5px',
    },
    formInput: {
        width: '98%',
        maxWidth: '98%',
        '&>div>input': {
            '-webkit-box-shadow': '0 0 0px 1000px #ffffff inset !important',
        },
    },
    inputAdornment: {
        color: 'gray',
    },
    raisedBtn: {
        width: '100%',
        border: 'none',
        borderRadius: '20px',
        backgroundColor: SIGNUP_PAGE_CSS.submitButtonBackgroundColor,
        color: SIGNUP_PAGE_CSS.submitButtonColor,
        lineHeight: '22px',
        '&:hover': {
            backgroundColor: '#ececec',
            color: '#222',
        },
        minWidth: '95%',
    },

    gitBtn: {
        width: '100%',
        border: 'none',
        borderRadius: '20px',
        backgroundColor: '#ececec',
        color: '#222',
        lineHeight: '22px',
        '&:hover': {
            backgroundColor: '#ececec',
            color: '#222',
        },
        minWidth: '95%',
    },

    divider: {
        borderTop: '1px solid #d9dadc',
        display: 'block',
        lineHeight: '1px',
        margin: '15px 0',
        position: 'relative',
        textAlign: 'center',
    },

    dividerTitle: {
        background: '#494949',
        color: '#fff',
        fontSize: '14pt',
        letterSpacing: '1px',
        padding: '0 20px',
        texTransform: 'uppercase',
    },

    responseText: {
        color: SIGNUP_PAGE_CSS.darkFontColor,
        textAlign: 'center',
        fontWeight: 500,
    },
    instructionTextForgot: {
        color: '#4d4d4d',
        marginBottom: '14px',
        fontSize: '13px',
        fontWeight: 'normal',
        textDecoration: 'none',
        marginTop: '25px',
        width: '70%',
        display: 'flex',
        alignItems: 'center',
    },
    instructionText: {
        marginBottom: '14px',
        textAlign: 'left',
        fontSize: SIGNUP_PAGE_CSS.normalFontSize,
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#979797',
        marginTop: '25px',
    },
    autoMargin: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '95%',
    },
    listItems: {
        display: 'flex',
        flexDirection: 'row',
        padding: '17px 100px 0px 0px',
        color: '#67686a',
        fontSize: '18px',
        lineHeight: '22px',
    },
    listItemRoot: {
        width: '210px',
    },
    loginDisabled: {
        color: SIGNUP_PAGE_CSS.submitButtonDisableColor,
        backgroundColor: SIGNUP_PAGE_CSS.submitButtonDisableBackgroundColor,
    },
    strength: {
        width: 'calc(100% - 6px)',
        border: '1px solid #c4c4c4',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        '&>p': {
            color: '#fff',
            height: 23,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            '-webkit-transition': 'width 0.5s',
        },
    },
    'strength-weak': {
        '&>p': {
            backgroundColor: '#e30000',
            width: '33%',
        },
    },
    'strength-medium': {
        '&>p': {
            backgroundColor: '#e86d05',
            width: '66%',
        },
    },
    'strength-strong': {
        '&>p': {
            backgroundColor: '#579609',
            width: '100%',
        },
    },
    'strength_color-weak': {
            color: '#e30000'
        
    },
    'strength_color-medium': {
            color: '#e86d05'
    },
    'strength_color-strong': {
            color: '#579609'
    },
    whiteCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        width: '65%',
        maxWidth: '900px',
        height: '500px',
        backgroundColor: '#fff',
        borderRadius: 50,
        margin: 50,
        boxShadow: '0px 2px 13px -4px',
    },
    whiteCardLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
    },
    whiteCardRight: {
        width: '50%',
        overflow: 'hidden',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopRightRadius: '50px',
        borderBottomRightRadius: '50px',
    },
    autoQ: {
        width: 'auto',
        height: '660px',
        position: 'absolute',
        top: '-40px',
        left: '0px',
    },
    autonomiqLogo: {
        width: '45%',
        height: 'auto',
        position: 'absolute',
        right: '65px',
    },
    loginWith: {
        margin: '25px 0px 5px 0px',
        fontSize: 14,
        color: '#979797',
    },
    placeholder: {
        '&>div>::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
    },
    firstTextField: {
        backgroundColor: 'white',
        width: '100%',
        height: SIGNUP_PAGE_CSS.textFieldHeight,
    },
    textField: {
        backgroundColor: 'white',
        width: '100%',
        height: SIGNUP_PAGE_CSS.textFieldHeight,
        marginTop: '10px',
    },
    signupNow: {
        width: '67%',
        marginRight: '10px',
        marginTop: '10px',
        paddingBottom: '5px',
        height: SIGNUP_PAGE_CSS.submitButtonHeight,
    },
});
